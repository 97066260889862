window.loadScript = function (url) {
    return new Promise(resolve => {
        let script = document.createElement('script');

        script.setAttribute('src', url);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', '');

        script.onload = resolve;
        script.onreadystatechange = resolve;

        document.head.appendChild(script);
    });
}
